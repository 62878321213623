// src/component/Login/Login.js
import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../../App';
import Logo from '../../Assets/Images/logo.png';
import './login.css';

function Login() {
  const { dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState(""); 
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          identifier,
          password
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log("Response Status:", response.status);
        console.log("Response Data:", errorData);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data.user);

      if (data.error) {
        console.log("Error:", data.error);
      } else {
        localStorage.setItem('jwt', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        dispatch({ type: "USER", payload: data.user });
        navigate('/');
      }
    } catch (error) {
      console.log("Login Error:", error);
    }
  };

  return (
    <div className='login-wrap'>
      <div className="login-left">
        {/* Optional: Add styling or background here */}
      </div>
      <div className="login-right">
        <div className="login-form">
          <div className="login-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="logiemail-input">
            <input
              type="text"
              placeholder="Email or Username"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
            />
          </div>
          <div className="logipass-input">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              className='show-hide'
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Hide" : "Show"}
            </span>
          </div>
          <button onClick={handleLogin}>Login</button>
          <Link to='/request-reset'>
            <span>Forgot Password?</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
