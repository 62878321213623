import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const Attendance = () => {
    const [data, setData] = useState([]);
    const [acNo, setAcNo] = useState('');

    const handleFileUpload = (e) => {
        const selectFile = new FileReader();
        selectFile.readAsBinaryString(e.target.files[0]);
        selectFile.onload = (e) => {
            const fileData = e.target.result;
            const workBook = XLSX.read(fileData, { type: 'binary' });
            const sheetName = workBook.SheetNames[0];
            const sheet = workBook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            setData(parsedData);

            if (parsedData.length > 0) {
                const firstRow = parsedData[0];
                if (firstRow["AC-No."]) {
                    setAcNo(firstRow["AC-No."]);
                } else {
                    console.error("AC-No. column not found in the uploaded file");
                }
            }
        };
    };

    const sendDataToBackend = async () => {
        if (!acNo) {
            console.error("AC-No. not found in the data");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance`, {
                acNo: acNo,
                data: data,
            });
            console.log(response.data); // Log success message or handle response as needed
        } catch (error) {
            console.error('Error sending data to backend:', error);
            // Handle error state or show user feedback
        }
    };

    const deleteDataInBackend = async () => {
        if (!acNo) {
            console.error("AC-No. not found in the data");
            return;
        }

        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/attendance/${acNo}`);
            console.log(response.data); // Log success message or handle response as needed
        } catch (error) {
            console.error('Error deleting data:', error);
            // Handle error state or show user feedback
        }
    };

    return (
        <div className="attendance-wrap">
            <h2>Attendance Upload</h2>
            <input type="file" accept=".xlsx, .xls, .csv" onChange={handleFileUpload} />
            <button onClick={sendDataToBackend}>Send Data to Backend</button>
            <button onClick={deleteDataInBackend}>Delete Data</button>
            {data.length > 0 && (
                <table>
                    <thead>
                        <tr>
                            {Object.keys(data[0]).map((key, index) => (
                                <th key={index}>{key}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                {Object.values(row).map((value, idx) => (
                                    <td key={idx}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Attendance;
