import React, { useState, useEffect, useCallback } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Button,
  Pagination,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./showprojects.css";

const ProjectTable = () => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    consultant: "",
    projectName: "",
    location: "",
    userName: "",
    projectStatus: "",
  });
  const [user, setUser] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [editableFields, setEditableFields] = useState({});
  const [allowedColumns, setAllowedColumns] = useState([]);

  const userRole = user.Role;

  const applyFilters = useCallback(
    (project) => {
      const { consultant, projectName, location, userName, projectStatus } =
        filters;
      const consultantLower = consultant?.toLowerCase() || "";
      const projectNameLower = projectName?.toLowerCase() || "";
      const locationLower = location?.toLowerCase() || "";
      const userNameLower = userName?.toLowerCase() || "";
      const projectStatusLower = projectStatus?.toLowerCase() || "";

      const matchesFilters =
        (consultantLower === "" ||
          project.Consultant?.toLowerCase().startsWith(consultantLower)) &&
        (projectNameLower === "" ||
          project.ProjectName?.toLowerCase().startsWith(projectNameLower)) &&
        (locationLower === "" ||
          project.Location?.toLowerCase().startsWith(locationLower)) &&
        (userNameLower === "" ||
          project.UserName?.toLowerCase().startsWith(userNameLower)) &&
        (projectStatusLower === "" ||
          project.ProjectStatus?.toLowerCase().startsWith(projectStatusLower));

      const canSeeProject = user?.PermissiontoSee?.includes(project.CreatedBy);

      return matchesFilters && canSeeProject;
    },
    [filters, user]
  );

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}projectusers`
      );
      const currentUser = response.data.allUsers.find(
        (u) => u._id === getUserIdFromUrl()
      );
      setUser(currentUser);
      setAllowedColumns(currentUser.AllowedColumns || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const params = {
        page,
        limit: 10,
        ...filters,
        userId: getUserIdFromUrl(),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}show-projects`,
        {
          params,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setRows(response.data.projects);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      toast.error("Error fetching data");
      console.error("Error fetching data:", error.response?.data || error);
    }
  }, [page, filters]);

  const getUserIdFromUrl = () => {
    const url = window.location.href;
    return url.substring(url.lastIndexOf("/") + 1);
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [page, filters, user, fetchData]);

  useEffect(() => {
    if (Array.isArray(rows)) {
      setFilteredRows(rows.filter(applyFilters));
    }
  }, [rows, filters, applyFilters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    setPage(1);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}delete-project/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setRows((prevRows) => prevRows.filter((row) => row._id !== id));
      toast.success("Project deleted successfully");
    } catch (error) {
      toast.error("Error deleting project");
    }
  };

  const handleEditClick = (id) => {
    setEditRowId(id);
    const rowToEdit = rows.find((row) => row._id === id);
    setEditableFields({
      QuotedValue: rowToEdit.QuotedValue,
      ProjectStatus: rowToEdit.ProjectStatus,
      Comment: rowToEdit.Comment,
      ProjectName: rowToEdit.ProjectName,
      Location: rowToEdit.Location,
      Consultant: rowToEdit.Consultant,
      Contractor: rowToEdit.Contractor,
      ProjectType: rowToEdit.ProjectType,
      MakeList: rowToEdit.MakeList,
      QuotedDate: rowToEdit.QuotedDate,
      FollowupDate: rowToEdit.FollowupDate,
      Person: rowToEdit.Person,
      Amount: rowToEdit.Amount,
      Status: rowToEdit.Status,
    });
  };

  const handleFieldChange = (e, field) => {
    const { value } = e.target;
    setEditableFields({ ...editableFields, [field]: value });
  };

  const handleSave = async () => {
    if (!editRowId) return;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}update-project/${editRowId}`,
        editableFields,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setRows((prevRows) =>
        prevRows.map((row) =>
          row._id === editRowId
            ? { ...row, ...response.data.updatedProject }
            : row
        )
      );
      setFilteredRows((prevFilteredRows) =>
        prevFilteredRows.map((row) =>
          row._id === editRowId
            ? { ...row, ...response.data.updatedProject }
            : row
        )
      );
      setEditRowId(null);
      setEditableFields({});
      toast.success("Project updated successfully");
    } catch (error) {
      toast.error("Error updating project");
    }
  };

  const handleCancel = () => {
    setEditRowId(null);
    setEditableFields({});
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "yyyy-MM-dd");
  };

  const exportToExcel = () => {
    const data = filteredRows.map((row) => ({
      "User Name": row.UserName,
      "Project Name": row.ProjectName,
      Location: row.Location,
      Consultant: row.Consultant,
      Contractor: row.Contractor,
      "Project Type": row.ProjectType,
      "Quoted Value": row.QuotedValue,
      "Quoted Date": row.QuotedDate ? formatDate(row.QuotedDate) : "",
      "Followup Date": row.FollowupDate ? formatDate(row.FollowupDate) : "",
      "Project Status": row.ProjectStatus,
      "Make List": row.MakeList,
      Person: row.Person,
      Amount: row.Amount,
      Status: row.Status,
      Comment: row.Comment,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Projects");
    XLSX.writeFile(wb, "Projects.xlsx");
  };

  const importFromExcel = () => {
    if (!selectedFile) {
      toast.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("userId", getUserIdFromUrl());

    axios
      .post(`${process.env.REACT_APP_API_URL}import-projects`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (Array.isArray(response.data.projects)) {
          setRows(response.data.projects);
          setFilteredRows(response.data.projects.filter(applyFilters));
          setSelectedFile(null); // Clear the selected file
          toast.success("File imported successfully");
        } else {
          toast.error("Error importing file");
        }
      })
      .catch((error) => {
        console.error("Error importing file:", error);
        toast.error("Error importing file");
      });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <>
      <div className="project-table">
        <TextField
          label="Consultant"
          name="consultant"
          value={filters.consultant}
          onChange={handleFilterChange}
        />
        <TextField
          label="Project Name"
          name="projectName"
          value={filters.projectName}
          onChange={handleFilterChange}
        />
        <TextField
          label="Location"
          name="location"
          value={filters.location}
          onChange={handleFilterChange}
        />
        <TextField
          label="User Name"
          name="userName"
          value={filters.userName}
          onChange={handleFilterChange}
        />
        <TextField
          label="Project Status"
          name="projectStatus"
          value={filters.projectStatus}
          onChange={handleFilterChange}
        />
        {userRole === "administrator" && (
          <>
            <Button onClick={exportToExcel}>Export to Excel</Button>
            <input type="file" accept=".xlsx" onChange={handleFileChange} />
            <Button onClick={importFromExcel}>Import from Excel</Button>
          </>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {allowedColumns.includes("UserName") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  User Name
                </TableCell>
              )}
              {allowedColumns.includes("ProjectName") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Project Name
                </TableCell>
              )}
              {allowedColumns.includes("Location") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Location
                </TableCell>
              )}
              {allowedColumns.includes("Consultant") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Consultant
                </TableCell>
              )}
              {allowedColumns.includes("Contractor") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Contractor
                </TableCell>
              )}
              {allowedColumns.includes("ProjectType") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Project Type
                </TableCell>
              )}
              {allowedColumns.includes("QuotedValue") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Quoted Value
                </TableCell>
              )}
              {allowedColumns.includes("QuotedDate") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Quoted Date
                </TableCell>
              )}
              {allowedColumns.includes("FollowupDate") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Followup Date
                </TableCell>
              )}
              {allowedColumns.includes("ProjectStatus") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Project Status
                </TableCell>
              )}
              {allowedColumns.includes("MakeList") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Make List
                </TableCell>
              )}
              {allowedColumns.includes("Person") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Person
                </TableCell>
              )}
              {allowedColumns.includes("Amount") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Amount
                </TableCell>
              )}
              {allowedColumns.includes("Status") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Status
                </TableCell>
              )}
              {allowedColumns.includes("Comment") && (
                <TableCell
                  style={{
                    width: "5%",
                    border: "1px solid black",
                    fontWeight: "600",
                  }}
                >
                  Comment
                </TableCell>
              )}

              <TableCell
                style={{
                  width: "5%",
                  border: "1px solid black",
                  fontWeight: "600",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ border: "1px solid black" }}>
            {filteredRows.map((row) => (
              <TableRow key={row._id} style={{ border: "1px solid black" }}>
                {allowedColumns.includes("UserName") && (
                  <TableCell >{row.UserName}</TableCell>
                )}
                {allowedColumns.includes("ProjectName") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <TextField
                        name="ProjectName"
                        value={editableFields.ProjectName || row.ProjectName}
                        onChange={(e) => handleFieldChange(e, "ProjectName")}
                      />
                    ) : (
                      row.ProjectName
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("Location") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <TextField
                        name="Location"
                        value={editableFields.Location || row.Location}
                        onChange={(e) => handleFieldChange(e, "Location")}
                      />
                    ) : (
                      row.Location
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("Consultant") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <TextField
                        name="Consultant"
                        value={editableFields.Consultant || row.Consultant}
                        onChange={(e) => handleFieldChange(e, "Consultant")}
                      />
                    ) : (
                      row.Consultant
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("Contractor") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <TextField
                        name="Contractor"
                        value={editableFields.Contractor || row.Contractor}
                        onChange={(e) => handleFieldChange(e, "Contractor")}
                      />
                    ) : (
                      row.Contractor
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("ProjectType") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <TextField
                        name="ProjectType"
                        value={editableFields.ProjectType || row.ProjectType}
                        onChange={(e) => handleFieldChange(e, "ProjectType")}
                      />
                    ) : (
                      row.ProjectType
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("QuotedValue") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <TextField
                        name="QuotedValue"
                        value={editableFields.QuotedValue || row.QuotedValue}
                        onChange={(e) => handleFieldChange(e, "QuotedValue")}
                      />
                    ) : (
                      row.QuotedValue
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("QuotedDate") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <TextField
                        name="QuotedDate"
                        type="date"
                        value={
                          editableFields.QuotedDate
                            ? formatDate(editableFields.QuotedDate)
                            : ""
                        }
                        onChange={(e) => handleFieldChange(e, "QuotedDate")}
                      />
                    ) : row.QuotedDate ? (
                      formatDate(row.QuotedDate)
                    ) : (
                      ""
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("FollowupDate") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <TextField
                        name="FollowupDate"
                        type="date"
                        value={
                          editableFields.FollowupDate
                            ? formatDate(editableFields.FollowupDate)
                            : ""
                        }
                        onChange={(e) => handleFieldChange(e, "FollowupDate")}
                      />
                    ) : row.FollowupDate ? (
                      formatDate(row.FollowupDate)
                    ) : (
                      ""
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("ProjectStatus") && (
                  <TableCell>
                    {editRowId === row._id ? (
                  <Select
                    className="select-field"
                    value={row.projectStatus}
                    onChange={(e) =>
                      handleFieldChange(row.id, "projectStatus", e.target.value)
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Status
                    </MenuItem>
                    <MenuItem value="Won">Won</MenuItem>
                    <MenuItem value="Lost">Lost</MenuItem>
                    <MenuItem value="Bidding">Bidding</MenuItem>
                  </Select>
                   ) : (
                    row.ProjectStatus
                  )}
                </TableCell>
                )}
                {allowedColumns.includes("MakeList") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <Select
                        name="MakeList"
                        value={editableFields.MakeList || row.MakeList}
                        onChange={(e) => handleFieldChange(e, "MakeList")}
                      >
                        <MenuItem value="" disabled>
                          Select Status
                        </MenuItem>
                        <MenuItem value="Make Approved">Make Approved</MenuItem>
                        <MenuItem value="Make Approved With Spec">
                          Make Approved With Spec
                        </MenuItem>
                        <MenuItem value="Not Approved">Not Approved</MenuItem>
                      </Select>
                    ) : (
                      row.MakeList
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("Person") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <TextField
                        className="text-field"
                        value={editableFields.Person || row.person}
                        onChange={(e) => handleFieldChange(e, "person")}
                      />
                    ) : (
                      row.Person
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("Amount") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <TextField
                        className="text-field"
                        type="text"
                        value={editableFields.Amount || row.amount}
                        onChange={(e) => handleFieldChange(e, "Amount")}
                      />
                    ) : (
                      row.Amount
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("Status") && (
                  <TableCell>
                    {editRowId === row._id ? (
                      <Select
                        className="select-field"
                        value={editableFields.Status || row.status}
                        onChange={(e) => handleFieldChange(e, "Status")}
                      >
                        <MenuItem value="" disabled>
                          Select Status
                        </MenuItem>
                        <MenuItem value="Won">N/A</MenuItem>
                        <MenuItem value="Lost">Transferd</MenuItem>
                        <MenuItem value="Bidding">Pending</MenuItem>
                      </Select>
                    ) : (
                      row.Status
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes('Comment') && (

                  <TableCell>
                  {editRowId === row._id ? (
                    <TextField
                    name="Comment"
                    value={editableFields.Comment || row.Comment}
                    onChange={(e) => handleFieldChange(e, "Comment")}
                    />
                  ) : (
                    row.Comment
                  )}
                </TableCell>
                )}
                <TableCell>
                  {editRowId === row._id ? (
                    <>
                      <Button onClick={handleSave}>Save</Button>
                      <Button onClick={handleCancel}>Cancel</Button>
                    </>
                  ) : (
                    <>
                      <Button onClick={() => handleEditClick(row._id)}>
                        Edit
                      </Button>
                      <Button onClick={() => handleDelete(row._id)}>
                        Delete
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={(event, value) => setPage(value)}
      />
    </>
  );
};

export default ProjectTable;
