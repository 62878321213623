import React, { useEffect, useState } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./Reports.css";

// Register Chart.js components and plugins
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJs.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

function Reports() {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userObject = JSON.parse(localStorage.getItem("user") || "{}");
  const userId = userObject._id || "";

  useEffect(() => {
    async function fetchStats() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}user-projects/stats/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        const data = await response.json();
        setStats(data.stats);
        setLoading(false);
      } catch (err) {
        setError(`Failed to fetch project stats. ${err.message}`);
        setLoading(false);
      }
    }
    fetchStats();
  }, [userId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const barData = {
    labels: stats.statusWiseStats.map((status) => status._id),
    datasets: [
      {
        label: "Number of Projects",
        data: stats.statusWiseStats.map((status) => status.statusTotalProjects),
        backgroundColor: "rgba(241, 218, 139, 0.979)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const doughnutData = {
    labels: stats.statusWiseStats.map((status) => status._id),
    datasets: [
      {
        label: "Total Amount",
        data: stats.statusWiseStats.map((status) => status.statusTotalAmount),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const lineData = {
    labels: stats.monthWiseStats.map(
      (monthStat) => `${monthStat._id.month}/${monthStat._id.year}`
    ),
    datasets: [
      {
        label: "Month-wise Quote Value",
        data: stats.monthWiseStats.map(
          (monthStat) => monthStat.monthTotalQuoteValue
        ),
        fill: false,
        borderColor: "rgba(8, 101, 201, 0.932)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="reports-container">
      <h2>Project Reports</h2>
      <div className="charts-wrapper">
        <div className="chart-container-1">
          <h3 className="chart-title">Total Projects: {stats.totalProjects}</h3>
        </div>
        <div className="chart-container-2">
          <h3 className="chart-title">Total Quote Value: ₹{stats.totalQuoteValue.toLocaleString()}</h3>
        </div>
        <div className="chart-container-3">
          <h3 className="chart-title">Total Quote Value: ₹{stats.totalWonValue.toLocaleString()}</h3>
        </div>
        <div className="chart-container-4">
          <Doughnut
            data={doughnutData}
            options={{
              plugins: {
                datalabels: {
                  formatter: (value, context) => {
                    const total = context.dataset.data.reduce(
                      (sum, val) => sum + val,
                      0
                    );
                    return total
                      ? `${((value / total) * 100).toFixed(2)}%`
                      : value;
                  },
                  color: "#fff",
                },
                legend: {
                  display: true,
                },
                tooltip: {
                  callbacks: {
                    label: function (tooltipItem) {
                      const value = tooltipItem.raw; 
                      const total = tooltipItem.chart.data.datasets[
                        tooltipItem.datasetIndex
                      ].data.reduce((sum, val) => sum + val, 0);
                      const percentage = total
                        ? ((value / total) * 100).toFixed(2) + "%"
                        : "0%";

                      return `${
                        tooltipItem.label
                      }: ₹${value.toLocaleString()} (${percentage})`;
                    },
                  },
                },
              },
            }}
          />
          <h3 className="chart-title">Project Report by Status</h3>
        </div>
        <div className="chart-container-5">
          <Bar data={barData} />
          <h3 className="chart-title">Projects by Status</h3>
        </div>
        <div className="chart-container-6">
          <Line data={lineData} />
          <h3 className="chart-title">Month-wise Quote Value</h3>
        </div>
      </div>
    </div>
  );
}

export default Reports;
