import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import ProjectTable from "./Showprojects";
import "./ProjectTracker.css";
import { ToastContainer, toast } from "react-toastify";

const ProjectTracker = () => {
  const { id } = useParams();
  const userId = id;

  const [rows, setRows] = useState([
    {
      id: 1,
      userName: "",
      projectName: "",
      location: "",
      consultant: "",
      contractor: "",
      projectType: "",
      quotedValue: "",
      quotedDate: "",
      followupDate: "",
      projectStatus: "",
      MakeList: "",
      person: "",
      amount: "",
      status: "",
      comment: "",
    },
  ]);

  const [projectDetails, setProjectDetails] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [userName, setUserName] = useState("");
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [currentCommentRow, setCurrentCommentRow] = useState(null);
  const [allowedColumns, setAllowedColumns] = useState([]);
  const [comment, setComment] = useState("");
  const suggestionsRef = useRef(null);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}project-names`
        );
        const result = await response.json();
        setProjectDetails(result.projectDetails || []);
      } catch (error) {
        console.error("Error fetching project names:", error);
      }
    };

    const fetchUserName = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}users/${userId}`
        );
        const result = await response.json();
        const fullName = `${result.FirstName || ""} ${result.LastName || ""}`;
        setUserName(fullName);
        setRows([
          {
            id: 1,
            userName: fullName,
            projectName: "",
            location: "",
            consultant: "",
            contractor: "",
            projectType: "",
            quotedValue: "",
            quotedDate: "",
            followupDate: "",
            projectStatus: "",
            MakeList: "",
            person: "",
            amount: "",
            status: "",
            comment: "",
          },
        ]);
        setAllowedColumns(result.AllowedColumns || []);
      } catch (error) {
        console.error("Error fetching user name:", error);
      }
    };

    fetchProjectDetails();
    fetchUserName();
  }, [userId]);

  const handleInputChange = (id, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );

    if (field === "projectName") {
      const filteredSuggestions = projectDetails.filter((project) =>
        project.projectName?.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);

      if (value === "") {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === id
              ? { ...row, location: "", consultant: "", contractor: "" }
              : row
          )
        );
      }
    }
  };

  const handleCommentClick = (rowId) => {
    setCurrentCommentRow(rowId);
    const existingComment = rows.find((row) => row.id === rowId)?.comment || "";
    setComment(existingComment);
    setIsCommentSectionOpen(true);
  };

  const handleSaveComment = () => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === currentCommentRow ? { ...row, comment } : row
      )
    );
    setIsCommentSectionOpen(false);
  };

  const handleCancelComment = () => {
    setIsCommentSectionOpen(false);
  };

  const handleSuggestionClick = (suggestion) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === 1
          ? {
              ...row,
              projectName: suggestion.projectName,
              location: suggestion.location,
              consultant: suggestion.consultant,
              contractor: suggestion.contractor,
            }
          : row
      )
    );
    setSuggestions([]);
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async () => {
    console.log("Submitting rows:", rows);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}add-project/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rows }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add projects");
      }

      const result = await response.json();
      console.log(result.message);

      setRows([
        {
          id: 1,
          userName: userName,
          projectName: "",
          location: "",
          consultant: "",
          contractor: "",
          projectType: "",
          quotedValue: "",
          quotedDate: "",
          followupDate: "",
          projectStatus: "",
          MakeList: "",
          person: "",
          amount: "",
          status: "",
          comment: "",
        },
      ]);

      // Show success toast
      toast.success("Projects added successfully!");
    } catch (error) {
      console.error("Error submitting projects:", error);
    }
  };

  return (
    <div className="projecttracker-wrap">
      <TableContainer className="add-project" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {allowedColumns.includes("UserName") && <TableCell>User Name</TableCell>}
              {allowedColumns.includes("ProjectName") && <TableCell>Project Name</TableCell>}
              {allowedColumns.includes("Location") && <TableCell>Location</TableCell>}
              {allowedColumns.includes("Consultant") && <TableCell>Consultant</TableCell>}
              {allowedColumns.includes("Contractor") && <TableCell>Contractor</TableCell>}
              {allowedColumns.includes("ProjectType") && <TableCell>Project Type</TableCell>}
              {allowedColumns.includes("QuotedValue") && <TableCell>Quoted Value</TableCell>}
              {allowedColumns.includes("QuotedDate") && <TableCell>Quoted Date</TableCell>}
              {allowedColumns.includes("FollowupDate") && <TableCell>Followup Date</TableCell>}
              {allowedColumns.includes("ProjectStatus") && <TableCell>Project Status</TableCell>}
              {allowedColumns.includes("MakeList") && <TableCell>Make List</TableCell>}
              {allowedColumns.includes("Person") && <TableCell>Person</TableCell>}
              {allowedColumns.includes("Amount") && <TableCell>Amount</TableCell>}
              {allowedColumns.includes("Status") && <TableCell>Status</TableCell>}
              {allowedColumns.includes("Comment") && <TableCell>Comment</TableCell>}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                {allowedColumns.includes("UserName") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      value={row.userName}
                      onChange={(e) =>
                        handleInputChange(row.id, "userName", e.target.value)
                      }
                      disabled
                    />
                  </TableCell>
                )}
                {allowedColumns.includes("ProjectName") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      value={row.projectName}
                      onChange={(e) =>
                        handleInputChange(row.id, "projectName", e.target.value)
                      }
                    />
                    {suggestions.length > 0 && (
                      <div ref={suggestionsRef} className="suggestions-dropdown">
                        {suggestions.map((suggestion, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion.projectName}
                          </MenuItem>
                        ))}
                      </div>
                    )}
                  </TableCell>
                )}
                {allowedColumns.includes("Location") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      value={row.location}
                      onChange={(e) =>
                        handleInputChange(row.id, "location", e.target.value)
                      }
                    />
                  </TableCell>
                )}
                {allowedColumns.includes("Consultant") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      value={row.consultant}
                      onChange={(e) =>
                        handleInputChange(row.id, "consultant", e.target.value)
                      }
                    />
                  </TableCell>
                )}
                {allowedColumns.includes("Contractor") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      value={row.contractor}
                      onChange={(e) =>
                        handleInputChange(row.id, "contractor", e.target.value)
                      }
                    />
                  </TableCell>
                )}
                {allowedColumns.includes("ProjectType") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      value={row.projectType}
                      onChange={(e) =>
                        handleInputChange(row.id, "projectType", e.target.value)
                      }
                    />
                  </TableCell>
                )}
                {allowedColumns.includes("QuotedValue") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      type="number"
                      value={row.quotedValue}
                      onChange={(e) =>
                        handleInputChange(row.id, "quotedValue", e.target.value)
                      }
                    />
                  </TableCell>
                )}
                {allowedColumns.includes("QuotedDate") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      type="date"
                      value={row.quotedDate}
                      onChange={(e) =>
                        handleInputChange(row.id, "quotedDate", e.target.value)
                      }
                    />
                  </TableCell>
                )}
                {allowedColumns.includes("FollowupDate") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      type="date"
                      value={row.followupDate}
                      onChange={(e) =>
                        handleInputChange(row.id, "followupDate", e.target.value)
                      }
                    />
                  </TableCell>
                )}
                {allowedColumns.includes("ProjectStatus") && (
                  <TableCell>
                  <Select
                    className="select-field"
                    value={row.projectStatus}
                    onChange={(e) =>
                      handleInputChange(row.id, "projectStatus", e.target.value)
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Status
                    </MenuItem>
                    <MenuItem value="Won">Won</MenuItem>
                    <MenuItem value="Lost">Lost</MenuItem>
                    <MenuItem value="Bidding">Bidding</MenuItem>
                  </Select>
                </TableCell>
                )}
                {allowedColumns.includes("MakeList") && (
                  <TableCell>
                  <Select
                    className="select-field"
                    value={row.MakeList}
                    onChange={(e) =>
                      handleInputChange(row.id, "MakeList", e.target.value)
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Status
                    </MenuItem>
                    <MenuItem value="Make Approved">Make Approved</MenuItem>
                    <MenuItem value="Make Approved With Spec">
                      Make Approved With Spec
                    </MenuItem>
                    <MenuItem value="Not Approved">Not Approved</MenuItem>
                  </Select>
                </TableCell>
                )}
                {allowedColumns.includes("Person") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      value={row.person}
                      onChange={(e) =>
                        handleInputChange(row.id, "person", e.target.value)
                      }
                    />
                  </TableCell>
                )}
                {allowedColumns.includes("Amount") && (
                  <TableCell>
                    <TextField
                      className="text-field"
                      type="text"
                      value={row.amount}
                      onChange={(e) =>
                        handleInputChange(row.id, "amount", e.target.value)
                      }
                    />
                  </TableCell>
                )}
                {allowedColumns.includes("Status") && (
              <TableCell>
              <Select
                className="select-field"
                value={row.status}
                onChange={(e) =>
                  handleInputChange(row.id, "status", e.target.value)
                }
              >
                <MenuItem value="" disabled>
                  Select Status
                </MenuItem>
                <MenuItem value="Won">N/A</MenuItem>
                <MenuItem value="Lost">Transferd</MenuItem>
                <MenuItem value="Bidding">Pending</MenuItem>
              </Select>
            </TableCell>
                )}
                {allowedColumns.includes("Comment") && (
                  <TableCell>
                    <Button
                      className="comment-btn"
                      onClick={() => handleCommentClick(row.id)}
                    >
                      Comment
                    </Button>
                    {isCommentSectionOpen && currentCommentRow === row.id && (
                      <div className="comment-overlay">
                        <textarea
                          className="comment-input"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                        <Button className="submit-btn" onClick={handleSaveComment}>
                          Save
                        </Button>
                        <Button className="submit-btn" onClick={handleCancelComment}>
                          Cancel
                        </Button>
                      </div>
                    )}
                  </TableCell>
                )}
                <TableCell>
                  <Button className="submit-btn" onClick={handleSubmit}>
                    Submit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="project-data-table">
          <ProjectTable />
        </div>
        <ToastContainer /> {/* Add this line to include the ToastContainer */}
    </div>
  );
};

export default ProjectTracker;
