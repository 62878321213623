import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import './styles/employeeProfile.css';
import { toast } from 'react-toastify';
import Preview from '../../Assets/Images/profile-prev.png';
import PersonalDetails from './PersonalDetails';
import ContactDetails from './ContactDetails';
import Myteam from './Myteam';

function EmployeeProfile() {
  const [currentView, setCurrentView] = useState('personalDetails');
  const [isEditMode, setIsEditMode] = useState(false);

  const renderContent = () => {
    switch (currentView) {
      case 'personalDetails':
        return <PersonalDetails isEditMode={isEditMode} />;
      case 'contactDetails':
        return <ContactDetails isEditMode={isEditMode} />;
      case 'myteam':
        return <Myteam isEditMode={isEditMode} />;
      default:
        return <PersonalDetails isEditMode={isEditMode} />;
    }
  };

  const handleSave = () => {
    // Add your save logic here
    setIsEditMode(false);
    toast.success('Saved successfully!');
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  return (
    <div className='profile-main-wrap'>
      {/* ...................profile Left area.............................. */}
      <div className="profile-menu">
        <div className="profileImg">
          <Avatar className='profile-pic' alt='chirag' src={Preview} sx={{ width: 100, height: 100 }} />
          <div className="designation">
            <p>Role: <span>User</span></p>
            <p>Department: <span>IT</span></p>
            <p>Designation: <span>Web Developer</span></p>
          </div>
        </div>
        <hr />
        <div className="profile-links">
          <ul>
            <li onClick={() => setCurrentView('personalDetails')}>Personal Details</li>
            <li onClick={() => setCurrentView('contactDetails')}>Contact Details</li>
            <li onClick={() => setCurrentView('myteam')}>Team</li>
            {/* Add more links here if needed */}
          </ul>
        </div>
      </div>

      {/* ...................profile Right area.............................. */}
      <div className="profile-details">
        {renderContent()}
        <div className="actions save-data">
          {isEditMode ? (
            <button onClick={handleSave}>Save</button>
          ) : (
            <button onClick={handleEdit}>Edit</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeeProfile;
