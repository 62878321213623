import React from 'react'
import './styles/contactDetails.css'

function ContactDetails({ isEditMode }) {
    return (
        <div>
            <div className="personal-details">
                <span>Contact Details</span>
                <hr />
                <div className="profileName">
                    <span>Telephone</span>
                    <hr />
                    <div className="dataInput">
                        <div className="inputarea">
                            <input type="text" id="first-name" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="first-name" className="input-title">Mobile</label>
                        </div>
                        <div className="inputarea">
                            <input type="text" id="middle-name" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="middle-name" className="input-title">Work</label>
                        </div>
                        <div className="inputarea">
                            <input type="text" id="middle-name" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="middle-name" className="input-title">Home</label>
                        </div>
                    </div>
                </div>
                {/* .....................Profile Name section Ends......................... */}

                <hr />
                {/* .............................Other Detail section starts......................... */}
                <div className="Other-details">
                <span>Email</span>
                    <div className="dataInput">
                        <div className="inputarea">
                            <input type="text" id="nationality-1" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="nationality-1" className="input-title">Work Email</label>
                        </div>
                        <div className="inputarea">
                            <input type="text" id="marital-status" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="marital-status" className="input-title">Other Email</label>
                        </div>
                    </div>
                    <hr />
                    <span>Address</span>
                    <div className="dataInput">
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">Street 1</label>
                        </div>
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">Street 2</label>
                        </div>
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">City</label>
                        </div>
                    </div>
                    <div className="dataInput">
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">State/Province</label>
                        </div>
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">Zip/Postal Code</label>
                        </div>
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">Country</label>
                        </div>
                    </div>
                    <div className="dataInput">
                        <div className='same-perm-address'>

                        <input type='checkbox' id="dateofJoin" readOnly={!isEditMode} className="peradd-checkBox" name="RelationwithContact" />
                        <label htmlFor="dateofJoin" >Check if Permanent Address is same as Address</label>
                        </div>
                    </div>
                    <hr />
                    <span>Address</span>
                    <div className="dataInput">
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">Street 1</label>
                        </div>
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">Street 2</label>
                        </div>
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">City</label>
                        </div>
                    </div>
                    <div className="dataInput">
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">State/Province</label>
                        </div>
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">Zip/Postal Code</label>
                        </div>
                        <div className="inputarea">
                            <input type='text' id="dateofJoin" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
                            <label htmlFor="dateofJoin" className="input-title">Country</label>
                        </div>
                    </div>
                   
                </div>
                {/* .............................Other Detail section ends......................... */}
                
                
                
            </div>
        </div>
    )
}

export default ContactDetails
