import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Sidenav from "./component/Sidenav/Sidenav.js";
import EmployeesDB from "./pages/Employees/EmployeesDB.js";
import AddEmployee from "./component/AddEmployee/AddEmployee.js";
import { initialState, reducer } from './Reducer/userReducer.js';
import ResetPassword from "./component/Login/ResetPassword.js";
import Login from "./component/Login/Login.js";
import RequestPassword from "./component/Login/RequestPassword.js";
import EmployeeProfile from "./component/EmployeeProfile/EmployeeProfile.js";
import ContactDetails from "./component/EmployeeProfile/ContactDetails.js";
import PersonalDetails from "./component/EmployeeProfile/PersonalDetails.js";
import ProfileDropdown from "./component/ProfileDropdown/ProfileDropdown.js";
import Notifications from "./component/Notification/Notifications.js";
import DashBoard from "./pages/DashBoard/DashBoard.js";
import Admin from "./pages/Admin/Admin.js";
import EditUser from "./component/EditUser/EditUser.js";
import ProjectTracker from "./pages/ProjectTracker/ProjectTracker.js";
import Attendance from "./pages/Attendance/Attendance.js";
import ProjectTable from "./pages/ProjectTracker/Showprojects.js";
import EmployeepermissionList from "./pages/Employees/EmployeepermissionList.js";
import Reports from "./component/Reports/Reports.js";
import "./App.css";

export const UserContext = createContext();

const fetchUserDetails = async (dispatch) => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("jwt");
  if (user && token) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}user-details`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        dispatch({ type: "USER", payload: data });
      } else {
        console.error('Failed to fetch user details:', response.status, response.statusText);
        // Handle unauthorized access (e.g., token expired or invalid)
        if (response.status === 401) {
          localStorage.removeItem('user');
          localStorage.removeItem('jwt');
          window.location.href = '/login';
        }
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }
};

const Routing = ({ isCollapsed, toggleNav }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(UserContext);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("jwt");

    if (user && token) {
      try {
        const parsedUser = JSON.parse(user);
        dispatch({ type: "USER", payload: parsedUser });
        fetchUserDetails(dispatch);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    } else {
      if (!(location.pathname.match(/^\/reset-password\/[^/]+$/) || location.pathname === '/request-reset' || location.pathname === '/login')) {
        navigate('/login');
      }
    }
  }, [dispatch, navigate, location.pathname]);

  const user = state.user;
  const isLoggedIn = user !== null && user !== undefined;

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      if (event.key === 'p' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    const overlay = document.createElement('div');
    overlay.className = 'screenshot-overlay';
    document.body.appendChild(overlay);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
      document.body.removeChild(overlay);
    };
  }, []);

  return (
    <>
      <header className="sticky-header">
        {isLoggedIn && <ProfileDropdown />}
      </header>
      <div className="home-wrap">
        {isLoggedIn && !location.pathname.match(/^\/reset-password\/[^/]+$/) && location.pathname !== '/request-reset' && location.pathname !== '/login' && (
          <>
            <div className={`left ${isCollapsed ? "collapsed" : ""}`}>
              <Sidenav userId={user._id} userRole={user.Role} isCollapsed={isCollapsed} toggleNav={toggleNav} />
              <button className="toggle-btn" onClick={toggleNav}>
                {isCollapsed ? (
                  <i className="fa-solid fa-bars"></i>
                ) : (
                  <i className="fa-solid fa-xmark"></i>
                )}
              </button>
            </div>
          </>
        )}
        <div className={`right ${!isLoggedIn ? 'full-width' : ''} ${isCollapsed ? "collapsed" : ""}`}>
          <Routes>
            {state.user?.Role === 'administrator' && (
              <>
                <Route path="/admin" element={<Admin />} />
                <Route path="/addemployee" element={<AddEmployee />} />
              </>
            )}
            <Route path="/edit-user/:id" element={<EditUser />} />
            <Route path="/employee-dashboard" element={<EmployeesDB />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/add-project/:id" element={<ProjectTracker />} />
            <Route path="/project-table" element={<ProjectTable />} />
            <Route path="/request-reset" element={<RequestPassword />} />
            <Route path="/employee-profile/:id" element={<EmployeeProfile />} />
            <Route path="/assign-user" element={<EmployeepermissionList />} />
            <Route path="/project-users/:projectId" element={<ProjectTable />} />
            <Route path="/" element={<DashBoard />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/reports/:id" element={<Reports />} />
            <Route path="/personal-details" element={<PersonalDetails />} />
            <Route path="/contact-details" element={<ContactDetails />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/login" element={<Login />} />
            {/* Default route for unmatched paths */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

const NotFound = () => {
  return <div>404 - Page Not Found</div>;
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleNav = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="App">
      <UserContext.Provider value={{ state, dispatch }}>
        <Router>
          <Routing isCollapsed={isCollapsed} toggleNav={toggleNav} />
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
