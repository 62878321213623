import React, { useState, useEffect, useRef } from "react";
import "./addemployee.css";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Preview from "../../Assets/Images/profile-prev.png";
import { useNavigate } from "react-router-dom";
import { TextField, Autocomplete } from "@mui/material";

function AddEmployee() {
  const [userRole] = useState(["user", "administrator"]);
  const [uploadImg, setUploadImg] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    MiddleName: "",
    DateofJoin: "",
    DOB: "",
    OfficalPhone: "",
    OfficeEmail: "",
    Email: "",
    Phone: "",
    Designation: "",
    EmergencyPhone: "",
    RelationwithContact: "",
    AdharNumber: "",
    BloodGroup: "",
    Address: "",
    PermanentAddress: "",
    Pan: "",
    Role: "user",
    Department: "",
    PolicyNumber: "",
    UAN: "",
    ProfileImageUrl: "",
  });

  const [departmentSuggestions, setDepartmentSuggestions] = useState([]);
  const [designationSuggestions, setDesignationSuggestions] = useState([]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}user-designations-departments`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        const result = await response.json();
        const { userDetails } = result;

        const uniqueDesignations = [
          ...new Set(userDetails.map((user) => user.designation)),
        ];
        const uniqueDepartments = [
          ...new Set(userDetails.map((user) => user.department)),
        ];

        setDesignationSuggestions(uniqueDesignations);
        setDepartmentSuggestions(uniqueDepartments);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleChange = (event, value, reason, name) => {
    if (reason === "selectOption") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const handleAddEmployee = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}addemployee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log("Response Status:", response.status);
        console.log("Response Data:", errorData);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.error) {
        console.log("Error:", data.error);
      }
      navigate("/employee-dashboard");
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadImg(reader.result);
        setFormData((prevState) => ({
          ...prevState,
          ProfileImageUrl: reader.result,
        }));
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="add-employee-wrap">
      <div className="page-title">
        <h1>EMPLOYEE DETAILS</h1>
        <Breadcrumbs />
      </div>
      <div className="basic-info">
        <div className="employee-img">
          <div className="profileImg">
            <label htmlFor="fileInput">
              {uploadImg ? (
                <img src={uploadImg} alt="upload-img" />
              ) : (
                <img src={Preview} alt="upload-img-placeholder" />
              )}
            </label>
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </div>
          <div className="addprofileImg-btn">
            <button onClick={handleButtonClick}>ADD IMAGE</button>
          </div>
        </div>

        <div className="employeedata">
          <div className="data-input">
            <div className="entryarea">
              <input
                type="text"
                className="firstname"
                value={formData.FirstName}
                onChange={(e) => handleChange(e, null, null, "FirstName")}
                name="FirstName"
              />
              <label htmlFor="firstname" className="input-title">
                First Name
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="middlename"
                value={formData.MiddleName}
                onChange={(e) => handleChange(e, null, null, "MiddleName")}
                name="MiddleName"
              />
              <label htmlFor="middlename" className="input-title">
                Middle Name
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="lastname"
                value={formData.LastName}
                onChange={(e) => handleChange(e, null, null, "LastName")}
                name="LastName"
              />
              <label htmlFor="lastname" className="input-title">
                Last Name
              </label>
            </div>

            <div className="entryarea">
              <input
                type="date"
                className="date-of-join"
                value={formData.DateofJoin}
                onChange={(e) => handleChange(e, null, null, "DateofJoin")}
                name="DateofJoin"
                id="date-picker"
              />
              <label htmlFor="date-of-join" className="input-title">
                Date of Joining
              </label>
            </div>

            <div className="entryarea">
              <Autocomplete
                options={designationSuggestions}
                value={formData.Designation}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "selectOption", "Designation")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Designation"
                    variant="outlined"
                    onChange={(e) => handleChange(e, null, null, "Designation")}
                  />
                )}
              />
            </div>

            <div className="entryarea">
              <select
                className="role"
                value={formData.Role}
                onChange={(e) => handleChange(e, null, null, "Role")}
                name="Role"
                id="role"
              >
                {userRole.map((role, index) => (
                  <option key={index} value={role}>
                    {role}
                  </option>
                ))}
              </select>
              <label htmlFor="Role" className="input-title">
                Role
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="official-phone"
                value={formData.OfficalPhone}
                onChange={(e) => handleChange(e, null, null, "OfficalPhone")}
                name="OfficialPhone"
              />
              <label htmlFor="official-phone" className="input-title">
                Official Phone No.
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="official-email"
                value={formData.OfficeEmail}
                onChange={(e) => handleChange(e, null, null, "OfficeEmail")}
                name="OfficialEmail"
              />
              <label htmlFor="official-email" className="input-title">
                Official Email
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ width: "90%", margin: "auto" }} />
      <div className="other-basic-info">
        <div className="employee-other-data">
          <div className="other-data-input">
            <div className="entryarea">
              <Autocomplete
                options={departmentSuggestions}
                value={formData.Department}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "selectOption", "Department")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Department"
                    variant="outlined"
                    onChange={(e) => handleChange(e, null, null, "Department")}
                  />
                )}
              />
            </div>

            <div className="entryarea">
              <input
                type="date"
                className="dob"
                value={formData.DOB}
                onChange={(e) => handleChange(e, null, null, "DOB")}
                name="DOB"
                id="dob-picker"
              />
              <label htmlFor="dob" className="input-title">
                Date of Birth
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="email"
                value={formData.Email}
                onChange={(e) => handleChange(e, null, null, "Email")}
                name="Email"
              />
              <label htmlFor="email" className="input-title">
                Email
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="phone"
                value={formData.Phone}
                onChange={(e) => handleChange(e, null, null, "Phone")}
                name="Phone"
              />
              <label htmlFor="phone" className="input-title">
                Phone
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="emergency-phone"
                value={formData.EmergencyPhone}
                onChange={(e) => handleChange(e, null, null, "EmergencyPhone")}
                name="EmergencyPhone"
              />
              <label htmlFor="emergency-phone" className="input-title">
                Emergency Phone No.
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="relation-with-contact"
                value={formData.RelationwithContact}
                onChange={(e) =>
                  handleChange(e, null, null, "RelationwithContact")
                }
                name="RelationwithContact"
              />
              <label
                htmlFor="relation-with-contact"
                className="input-title"
              >
                Relation with Emergency Contact
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="adhar-number"
                value={formData.AdharNumber}
                onChange={(e) => handleChange(e, null, null, "AdharNumber")}
                name="AdharNumber"
              />
              <label htmlFor="adhar-number" className="input-title">
                Adhar Number
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="blood-group"
                value={formData.BloodGroup}
                onChange={(e) => handleChange(e, null, null, "BloodGroup")}
                name="BloodGroup"
              />
              <label htmlFor="blood-group" className="input-title">
                Blood Group
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="address"
                value={formData.Address}
                onChange={(e) => handleChange(e, null, null, "Address")}
                name="Address"
              />
              <label htmlFor="address" className="input-title">
                Address
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="permanent-address"
                value={formData.PermanentAddress}
                onChange={(e) =>
                  handleChange(e, null, null, "PermanentAddress")
                }
                name="PermanentAddress"
              />
              <label
                htmlFor="permanent-address"
                className="input-title"
              >
                Permanent Address
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="pan"
                value={formData.Pan}
                onChange={(e) => handleChange(e, null, null, "Pan")}
                name="Pan"
              />
              <label htmlFor="pan" className="input-title">
                Pan No.
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="policy-number"
                value={formData.PolicyNumber}
                onChange={(e) => handleChange(e, null, null, "PolicyNumber")}
                name="PolicyNumber"
              />
              <label htmlFor="policy-number" className="input-title">
                Policy Number
              </label>
            </div>

            <div className="entryarea">
              <input
                type="text"
                className="uan"
                value={formData.UAN}
                onChange={(e) => handleChange(e, null, null, "UAN")}
                name="UAN"
              />
              <label htmlFor="uan" className="input-title">
                UAN Number
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="submit-area">
        <button onClick={handleAddEmployee}>ADD</button>
      </div>
    </div>
  );
}

export default AddEmployee;
