import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./edituser.css";

const EditUser = () => {
  const { id } = useParams(); // Extract id parameter from URL
  const navigate = useNavigate();

  const [user, setUser] = useState({
    Role: "",
    FirstName: "",
    LastName: "",
    status: "",
    Password: "",
    confirmPassword: "",
    UserID: "",
  });

  const [changePassword, setChangePassword] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}users/${id}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const userData = await response.json();
        setUser(userData); // Populate user state with fetched data
      } catch (err) {
        console.error("Error:", err);
        alert("An error occurred while fetching the user");
      }
    };

    if (id) {
      fetchUser();
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleChangePasswordToggle = () => {
    setChangePassword(!changePassword);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}save-user/${id}`, {
        method: "POST", // Use POST method for updating user
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
          ...user,
          changePassword,
        }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error);
      }

      alert("User saved successfully");
      navigate("/admin"); // Redirect to the admin page or another page after saving
    } catch (err) {
      console.error("Error:", err);
      alert("An error occurred while saving the user");
    }
  };

  const handleCancel = () => {
    navigate("/admin"); // Implement cancel logic here, redirecting to the admin page
  };

  const roleOptions = () => {
    return (
      <>
        <option value="user" selected={user.Role === "user"}>User</option>
        <option value="administrator" selected={user.Role === "administrator"}>Admin</option>
      </>
    );
  };

  return (
    <div className="edit-user">
      <div className="form-group">
        <label>User Role*</label>
        <select
          name="Role"
          value={user.Role || "user"}
          onChange={handleInputChange}
        >
          {roleOptions()}
        </select>
      </div>
      <div className="form-group">
        <label>First Name*</label>
        <input
          type="text"
          name="FirstName"
          value={user.FirstName}
          onChange={handleInputChange}
          disabled
        />
      </div>
      <div className="form-group">
        <label>Last Name*</label>
        <input
          type="text"
          name="LastName"
          value={user.LastName}
          onChange={handleInputChange}
          disabled
        />
      </div>
      <div className="form-group">
        <label>Status*</label>
        <select name="status" value={user.status} onChange={handleInputChange}>
          <option value="Enabled">Enabled</option>
          <option value="Disabled">Disabled</option>
        </select>
      </div>
      <div className="form-group">
        <label>User ID*</label>
        <input
          type="text"
          name="UserID"
          value={user.UserID}
          onChange={handleInputChange}
          disabled // Make UserID field read-only
        />
      </div>
      <div className="form-group">
        <label>Change Password?</label>
        <input
          type="checkbox"
          checked={changePassword}
          onChange={handleChangePasswordToggle}
        />
      </div>
      {changePassword && (
        <>
          <div className="form-group">
            <label>Password*</label>
            <input
              type="password"
              name="Password"
              value={user.Password}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Confirm Password*</label>
            <input
              type="password"
              name="confirmPassword"
              value={user.confirmPassword}
              onChange={handleInputChange}
            />
          </div>
        </>
      )}
      <div className="form-group">
        <p>
          For a strong password, please use a hard to guess combination of text
          with upper and lower case characters, symbols, and numbers
        </p>
      </div>
      <div className="form-group">
        <button onClick={handleCancel}>Cancel</button>
        <button onClick={handleSave}>Save</button>
      </div>
    </div>
  );
};

export default EditUser;
